/* angular */
import { DetailRow } from '@app/core/models/detail-list.model';
import { IUser, UserProfile } from '@app/core/models/user.model';
import { createReducer, on } from '@ngrx/store';

/* actions */
import * as UsersDataStoreActions from './users-data-store.actions';

/* state key */
export const featureKey = 'users';

/* state interface */

export interface UsersDataStore {
  loading: boolean | null,
  userSelected: {
    error: any,
    userInfo: IUser | null,
    userDetailsList: DetailRow[] | null,
  },
  userEditing: {
    userProfile?: UserProfile | null
    error: any
  },
  m2mToken: string | null,
  error: any
}


/* initial state */
export const initialState: UsersDataStore = {
  loading: null,
  userSelected: {
    error: null,
    userInfo: null,
    userDetailsList: null,
  },
  userEditing: {
    userProfile: null,
    error: null
  },
  m2mToken: null,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(UsersDataStoreActions.getUserByEmail, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UsersDataStoreActions.getUserByEmailSuccessfully, (state, action) => {
    return {
      ...state,
      loading: false,
      userSelected: {
        error: null,
        userInfo: action.payload.userEditing?.userInfo,
        userDetailsList: action.payload.userEditing?.userDetailsList,
      }
    };
  }),
  on(UsersDataStoreActions.getUserByEmailFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      userSelected: {
        error: action.payload.error,
        userInfo: null,
        userDetailsList: null,
      }
    };
  }),
  on(UsersDataStoreActions.createUserProfile, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UsersDataStoreActions.createUserProfileSuccessfully, (state, action) => {
    return {
      ...state,
      loading: false,
      userEditing: {
        ...state.userEditing,
        userProfile: action.payload.userProfile,
        error: null
      }
    };
  }),
  on(UsersDataStoreActions.createUserProfileFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      userEditing: {
        ...state.userEditing,
        error: action.payload.error,
        userProfile: null
      }
    };
  }),
  on(UsersDataStoreActions.createUser, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UsersDataStoreActions.createUserSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null
    };
  }),
  on(UsersDataStoreActions.createUserFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.payload.error
    };
  }),
  on(UsersDataStoreActions.getM2MToken, (state, action) => ({
    ...state,
    m2mToken: null,
  })),

  on(UsersDataStoreActions.getM2MTokenSuccess, (state, action) => ({
    ...state,
    m2mToken: action.payload.m2mToken,
  })),

  on(UsersDataStoreActions.getM2MTokenFailure, (state, action) => ({
    ...state,
    m2mToken: null,
    error: action.payload.error
  })),
  // Don't reset M2MToken
  on(UsersDataStoreActions.resetUserState, (state) => {
    return {
      ...state,
      loading: null,
      userSelected: {
        error: null,
        userInfo: null,
        userDetailsList: null,
      },
      userEditing: {
        userProfile: null,
        error: null
      },
      error: null
    };
  }),

  on(UsersDataStoreActions.resetUser, (state: any, action) => {
    state = { ...state };
    Object.keys(action.payload).forEach((key: any) => {
      state[key] = action.payload.key;
    });
    return { ...state };
  }),
);


