import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from '@app/core/models/user.model';
import { environment as env } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CloudApiService {

  constructor(
    private http: HttpClient
  ) { }

  getM2MToken(user: IUser) {
    const url = `${env.cloudApi}`;
    return this.http.post(url, {user}, { observe: 'response' });
  }
}
