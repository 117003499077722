/* Angular */
import { IGetGroupsParams, IGroup } from '@app/core/models/group.model';
import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  InitGroup = '[GROUP] InitGroup',
  GetGroups = '[GROUP] GetGroups',
  GetGroupsSuccess = '[GROUP] GetGroupsSuccess',
  GetGroupsFailure = '[GROUP] GetGroupsFailure',
  AssignMemberToGroup = '[GROUP] AssignMemberToGroup',
  AssignMemberToGroupSuccess = '[GROUP] AssignMemberToGroupSuccess',
  AssignMemberToGroupFailure = '[GROUP] AssignMemberToGroupFailure',
  ResetGroup = '[GROUP] ResetUser',
}

/* actions */
export const initGroup = createAction(ActionTypes.InitGroup);
export const getGroups = createAction(ActionTypes.GetGroups, props<{ payload: { params: IGetGroupsParams, newToken: string } }>());
export const getGroupsSuccess = createAction(ActionTypes.GetGroupsSuccess, props<{ payload: { groups: IGroup[] } }>());
export const getGroupsFailure = createAction(ActionTypes.GetGroupsFailure, props<{ payload: { error: any } }>());

export const assignMemberToGroup = createAction(ActionTypes.AssignMemberToGroup, props<{ payload: { groupId: string, params: string[], newToken: string } }>());
export const assignMemberToGroupSuccess = createAction(ActionTypes.AssignMemberToGroupSuccess);
export const assignMemberToGroupFailure = createAction(ActionTypes.AssignMemberToGroupFailure, props<{ payload: { error: any } }>());

export const resetGroup = createAction(ActionTypes.ResetGroup, props<{ payload: any }>());

