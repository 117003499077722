/* angular */
import { createReducer, on } from '@ngrx/store';

import { IGroup } from "@app/core/models/group.model";

/* state interface */
export interface GroupState {
  initialized: boolean;
  loading: boolean | null;
  groups: IGroup[] | null;
  error: any | null
}

/* initial values */
export const initialState: GroupState = {
  initialized: false,
  loading: null,
  groups: null,
  error: null
};


/* actions */
import * as GroupsDataStoreActions from './groups-data-store.actions';

/* state key */
export const featureKey = 'groups';

export const reducer = createReducer(
  initialState,
  /* reducer functions for actions */
  on(GroupsDataStoreActions.initGroup, (state, action) => ({
    ...state,
    initialized: true
  })),

  on(GroupsDataStoreActions.getGroups, (state, action) => ({
    ...state,
  })),

  on(GroupsDataStoreActions.getGroupsSuccess, (state, action) => ({
    ...state,
    error: null,
    groups: action.payload.groups
  })),

  on(GroupsDataStoreActions.getGroupsFailure, (state, action) => ({
    ...state,
    groups: null,
    error: action.payload.error
  })),

  on(GroupsDataStoreActions.assignMemberToGroup, (state, action) => ({
    ...state,
    loading: true,
  })),

  on(GroupsDataStoreActions.assignMemberToGroupSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: null,
  })),

  on(GroupsDataStoreActions.assignMemberToGroupFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error
  })),

  on(GroupsDataStoreActions.resetGroup, (state: any, action) => {
    state = { ...state };
    Object.keys(action.payload).forEach((key: any) => {
      state[key] = action.payload.key;
    });
    return { ...state };
  }),
);
