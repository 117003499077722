import { Permissions } from "@app/core/consts/permission.const";

export const permissions = {
  defaultRedirectUrl: '/no-permission',
  defaultPermPrefix: 'core_admin',
  routes: [
    ['users/', Permissions.ADMIN_PROFILES],
    ['users/assign-profile', Permissions.ADMIN_PROFILES],
    ['users/assign-group', Permissions.ADMIN_PROFILES],
    ['users/create-user', Permissions.ADMIN_PROFILES],
    ['credentials/', Permissions.API_CREDENTIALS],
  ],
};

export const appName = 'core-admin';
