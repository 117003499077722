import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import * as fromGroupDataStore from './groups-data-store.reducer';
import { GroupsDataStoreEffects } from './groups-data-store.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    /* datastores */
    StoreModule.forFeature(
      fromGroupDataStore.featureKey,
      fromGroupDataStore.reducer,
      { initialState: fromGroupDataStore.initialState }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 50 states
    }),
    EffectsModule.forRoot([GroupsDataStoreEffects])
  ]
})
export class GroupsDataStoreModule { }
