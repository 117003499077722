/* angular */
import { DetailRow } from '@app/core/models/detail-list.model';
import { IUser, UserProfile } from '@app/core/models/user.model';
import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  InitUsers = '[USERS] InitUsers',
  GetUserByEmail = '[USERS] GetUserByEmail',
  GetUserByEmailSuccessfully = '[USERS] GetUserByEmailSuccessfully',
  GetUserByEmailFailure = '[USERS] GetUserByEmailFailure',
  CreateUserProfile = '[USERS] CreateUserProfile',
  CreateUserProfileSuccessfully = '[USERS] CreateUserProfileSuccessfully',
  CreateUserProfileFailure = '[USERS] CreateUserProfileFailure',
  CreateUser = '[USERS] CreateUser',
  CreateUserSuccess = '[USERS] CreateUserSuccess',
  CreateUserFailure = '[USERS] CreateUserFailure',
  ResetUserState = '[USERS] ResetUserState',
  ResetUser = '[USERS] ResetUser',
  GetM2MToken = '[APP] GetM2MToken',
  GetM2MTokenSuccess = '[APP] GetM2MTokenSuccess',
  GetM2MTokenFailure = '[APP] GetM2MTokenFailure',
}

/* actions */
export const initUsers = createAction(ActionTypes.InitUsers);
export const getUserByEmail = createAction(ActionTypes.GetUserByEmail,
  props<{ payload: { email: string | null } }>());
export const getUserByEmailSuccessfully = createAction(ActionTypes.GetUserByEmailSuccessfully,
  props<{
    payload: {
      userEditing: {
        userInfo: IUser | null,
        userDetailsList: DetailRow[] | null
      }
    }
  }>());
export const getUserByEmailFailure = createAction(ActionTypes.GetUserByEmailFailure,
  props<{ payload: { error: any } }>());
export const createUserProfile = createAction(ActionTypes.CreateUserProfile,
  props<{ payload: { userProfile: UserProfile, newToken: string } }>());
export const createUserProfileSuccessfully = createAction(ActionTypes.CreateUserProfileSuccessfully,
  props<{ payload: { userProfile: UserProfile } }>());
export const createUserProfileFailure = createAction(ActionTypes.CreateUserProfileFailure,
  props<{ payload: { error: any } }>());
export const createUser = createAction(ActionTypes.CreateUser, props<{ payload: {params: any, newToken: string} }>());
export const createUserSuccess = createAction(ActionTypes.CreateUserSuccess);
export const createUserFailure = createAction(ActionTypes.CreateUserFailure, props<{ payload: { error: any } }>());
// Don't reset M2MToken
export const resetUserState = createAction(ActionTypes.ResetUserState);
export const resetUser = createAction(ActionTypes.ResetUser, props<{ payload: any }>());

export const getM2MToken = createAction(ActionTypes.GetM2MToken, props<{ payload: { user: IUser } }>());
export const getM2MTokenSuccess = createAction(ActionTypes.GetM2MTokenSuccess, props<{ payload: { m2mToken: string } }>());
export const getM2MTokenFailure = createAction(ActionTypes.GetM2MTokenFailure, props<{ payload: { error: any } }>());
