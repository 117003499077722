import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppDataStoreModule } from './app/app-data-store.module';
import { UsersDataStoreModule } from './users/users-data-store.module';
import { ApplicationDataStoreModule } from './applications/application-data-store.module';
import { GroupsDataStoreModule } from './groups/groups-data-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AppDataStoreModule,
    UsersDataStoreModule,
    ApplicationDataStoreModule,
    GroupsDataStoreModule
  ],
  exports: [
    StoreModule,
    EffectsModule
  ]
})
export class DataStoreModule { }
