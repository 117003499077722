
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SidenavHeaderConfig, SidenavFooterConfig, SidenavMenuConfig } from "@app/shared/config/sidenav.config";
import { environment } from '@environments/environment';
import { PermissionsService } from '@zonar-ui/auth';
import { Permissions } from './core/consts/permission.const';
import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';
import { DataDogService } from './core/services/datadog.service';
import { Store } from '@ngrx/store';
import { getM2MToken } from './shared/data-store/users/users-data-store.actions';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  //mock sidenav
  isSidenavOpen: boolean = true;
  sidenavHeader = SidenavHeaderConfig;
  sidenavMenu: any[] = SidenavMenuConfig;
  sidenavFooter = SidenavFooterConfig;
  interval: any;
  sidenavParams: SidenavParams = {
    mobileOpened: true,
    expanded: true,
    lockExpansion: false,
    useDefaultHelpItem: false,
    hideChangeCompanyButton: false,
    footerOffset: false,
    suppressChangeCompanyDialog: true
  };
  userProvisioningSidenavItem: any = {
    text: 'User Provisioning',
    icon: 'home',
    routerLink: '/users',
    route: 'users',
    routeLevel: 2,
    isExpanded: false,
    children: [],
  };

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private datadog: DataDogService,
    private store: Store<any>
  ) {
    this.renderer.addClass(document.body, 'zonar-default-theme');
  }

  ngOnInit(): void {
    this.getM2MToken();
    this.interval = setInterval(() => {
      this.getM2MToken();
    }, 82800000);

    this.permissionsService.hasPermission(Permissions.ADMIN_PROFILES).subscribe(isAdminProfile => {
      if (isAdminProfile) {
        this.sidenavMenu.find(item => item.appId === environment.auth.applicationId)?.children.push(this.userProvisioningSidenavItem);
      }
    });

  }

  getM2MToken() {
    this.permissionsService.getUser().pipe(filter(user => Boolean(user))).subscribe(user => {
      this.store.dispatch(getM2MToken({ payload: { user } }));
    });
  }


  // #region Side Nav Functions
  onSidenavToggled(event: boolean) {
    // TODO
  }

  onSideNavMobileMenuButtonToggled(event: boolean) {
    this.sidenavParams.mobileOpened = true;
  }

  setCurrentCompanyByFormSelection(event: any) {
    // TODO
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
