export const environment = {
  name: 'development',
  authEnv: 'development',
  region: 'NA',
  appUrl: 'https://core-admin.dev.zonarsystems.net',
  production: false,
  coreEntityApiBase: {
    url: `https://api-dev.zonarsystems.net/core/v1`,
    urlCVIConfig: `https://zonar-nonprod-dev.apigee.net/evir/v1`
  },
  cloudApi: 'https://us-west1-self-service-work-na-e783.cloudfunctions.net/getM2MTokenDev',
  auth: {
    clientID: 'Nr7TY3UkXdD5zVegMzH1yz5kKqLI2pBo',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    applicationId: 'd9178149-a27f-43a1-ac38-a4b74503d147',
    authURL: 'https://manage.auth0.com/dashboard/us/zonar-dev/apis/5ac3ad2d81db7911172f0f84/test'
  },
  sidenavHelpUrl: 'https://zonarsystemshelp.zendesk.com/hc/en-us',
  datadog: {
    applicationId: '084d356a-5d20-4d2d-a3e2-28e3f0130dfb',
    clientToken: 'puba56b2735916194f9cd9a671c3ddf06e9',
    site: 'us5.datadoghq.com',
  },
};
